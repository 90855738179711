$primary: #181a1b !default;
$secondary: #00b3ff !default;

@import 'bootstrap/scss/bootstrap.scss';

body {
  font-family: 'Monaco', sans-serif;
}

a {
  color: white;
  font-size: 1.2rem;
  &:hover { color: $secondary; }
}

ul.social li {
  display: inline;
}

.o-Background-Landing {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: table;
  background: #0b1419;
  z-index: -1;
}

.o-Background-homewrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-Background-homeinner {
  background-image: url('images/landing.jpg');
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  will-change: transform;
}

.o-ContactInfo-iconwidth {
  min-width: 1.2rem;
}

.o-ContactInfo-transparentBackground {
  background-color: rgba(0,0,0,0.70);
}

.o-ContactInfo-topPadding {
  padding-top: 10vh;
}

.o-ContactInfo-PhotoWidth {
  width: 50%;
  max-width: 20vh;
}

.o-Footer-Border {
  border-top: .2rem solid #666b71;
}

.o-ContactInfo-smallFontsize {
  font-size: 10px;
  font-style: italic;
}

.o-SquareProfileImage-imagewidth {
  width: 10rem;
  border-radius: 100%;
}

.o-RectangleProfileImage-position {
  z-index: 1;
  top: 7%;
  left: 65%;
  right: 3%;
  position: absolute;
}

.o-RectangleProfileImage-cardwidth {
  max-width: 30vh;
}

.o-RectangleProfileImage-imageheight {
  max-height: 60vh;
}